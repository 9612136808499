import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import AcceptRequestButton from './AcceptRequestButton';
import RejectRequestButton from './RejectRequestButton';

const OperativeRequestActions = ({ selectedRequest }) => (
  <Grid>
    <AcceptRequestButton operativeRequestId={selectedRequest} />
    <RejectRequestButton operativeRequestId={selectedRequest} />
  </Grid>
);

OperativeRequestActions.propTypes = {
  selectedRequest: PropTypes.string.isRequired,
};

export default OperativeRequestActions;
