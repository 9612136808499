import React, { useCallback, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { BottomSelectButton } from '@fingo/lib/components/buttons';
import { useBooleanState } from '@fingo/lib/hooks';
import { CurrencyFilter } from '@fingo/lib/components/filters';
import { CreditConciliationMovements, DebitConciliationMovements } from './components';
import ConfirmConciliation from './components/ConfirmConciliation';

const Conciliation = () => {
  const [open, toggleOpen] = useBooleanState(false);
  const [leftSideSelectedId, setLeftSideSelectedId] = useState([]);
  const [rightSideSelectedId, setRightSideSelectedId] = useState([]);
  const clearSelection = useCallback(() => {
    setLeftSideSelectedId([]);
    setRightSideSelectedId([]);
  }, []);
  const [currency, setCurrency] = useState('');

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <CurrencyFilter currency={currency} setCurrency={setCurrency} />
      </Box>
      <Grid container spacing={1} height="100%">
        <Grid item xs={6}>
          <CreditConciliationMovements
            customQueryVariables={{
              debit_Gt: 0,
            }}
            hideColumns={['credit']}
            selectionModel={leftSideSelectedId}
            setSelecionModel={setLeftSideSelectedId}
            title="Cuentas por pagar/Ingresos"
            currency={currency}
          />
        </Grid>
        <Grid item xs={6}>
          <DebitConciliationMovements
            customQueryVariables={{
              credit_Gt: 0,
            }}
            hideColumns={['debit']}
            selectionModel={rightSideSelectedId}
            setSelecionModel={setRightSideSelectedId}
            title="Cuentas por cobrar/Egresos"
            currency={currency}
          />
        </Grid>
      </Grid>
      {leftSideSelectedId.length + rightSideSelectedId.length > 0 && (
        <BottomSelectButton
          variant="contained"
          color="primary"
          onClick={toggleOpen}
        >
          Conciliar
        </BottomSelectButton>
      )}
      <ConfirmConciliation
        open={open}
        close={toggleOpen}
        debitMovementIds={leftSideSelectedId}
        setLeftSideSelectedId={setLeftSideSelectedId}
        creditMovementIds={rightSideSelectedId}
        setRightSideSelectedId={setRightSideSelectedId}
        clearSelection={clearSelection}
      />
    </>
  );
};

export default Conciliation;
