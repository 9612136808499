/* eslint-disable import/prefer-default-export */
import { LongTextCell } from '@fingo/lib/components/cells';
import { AvatarCell } from '@fingo/lib/components/dataGridCells';
import { Grid, Typography } from '@mui/material';
import React from 'react';

const documentTypeValueGetter = ({ row }) => {
  const plural = row.operativerequestmanagerSet.length > 1;
  if (row.operativerequestmanagerSet[0].invoice) {
    return `${row.operativerequestmanagerSet.length} Factura${plural ? 's' : ''}`;
  }
  return `${row.operativerequestmanagerSet.length} Ordering${plural ? 's' : ''}`;
};

const requestTypeToEmogi = {
  MOVE_TO_PENDINGRATIFICATION: '📬',
  MOVE_TO_PENDINGDOCUMENTSREVIEW: '📑',
  CHANGE_OPERATION_CONDITIONS: '💸',
  CHANGE_TO_INTERNAL_CESION: '⏳',
  UNDO_CONCILIATION: '🔐',
};

export const OPERATIVE_REQUESTS_COLUMNS = [
  {
    field: 'id',
    headerName: 'N° Solicitud',
    headerAlign: 'left',
    flex: 0.5,
    valueGetter: ({ row }) => row.id,
  },
  {
    field: 'requester',
    headerName: 'Solicitante',
    headerAlign: 'right',
    flex: 0.5,
    renderCell: ({ row }) => <AvatarCell user={row.requester} align="left" />,
  },
  {
    field: 'operativeRequestType',
    headerName: 'Tipo de solicitud',
    flex: 1,
    valueGetter: ({ row }) => (
      <Grid container display="flex" spacing={2}>
        <Grid item>
          <Typography variant="h4">
            {requestTypeToEmogi[row.operativeRequestType.code]}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            {row.operativeRequestType.name}
          </Typography>
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'documentType',
    headerName: 'Tipo documentos',
    flex: 1,
    valueGetter: documentTypeValueGetter,
  },
  {
    field: 'comment',
    headerName: 'Comentario',
    flex: 2,
    renderCell: ({ row }) => <LongTextCell title="Comentario" text={row.comment} />,
  },
];
